var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.visible,"width":600},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('VCard',{attrs:{"width":"600"}},[(_vm.visible)?_c('ModalWrapper',{attrs:{"title":"Перевод в другую группу мастеров"},on:{"confirm":_vm.handleSubmit,"cancel":_vm.handleClose,"close":_vm.handleClose}},[_c('div',{staticClass:"text-body-1 space-b-8"},[_vm._v("Чтобы перевести мастера"+_vm._s(' ')),_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.masterFullName))]),_vm._v(_vm._s(' ')+" необходимо выбрать новую группу мастеров и ее тип. А так же добавить причину перевода и оставить комментарий, который отобразится у мастера.")]),_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"oldMasterGroupId","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"label":"Старая группа","placeholder":"Старая группа","options":_vm.oldMasterGroups,"outlined":"","invalid":errors.length > 0,"error":errors[0]},on:{"input":_vm.fetchAvailableGroups},model:{value:(_vm.oldMasterGroupId),callback:function ($$v) {_vm.oldMasterGroupId=$$v},expression:"oldMasterGroupId"}})]}}],null,false,986141348)}),_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"newGroupType","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"label":"Тип группы","placeholder":"Тип группы","options":_vm.availableGroupTypes,"outlined":"","invalid":errors.length > 0,"error":errors[0]},on:{"input":_vm.handleSelectGroupType},model:{value:(_vm.newGroupType),callback:function ($$v) {_vm.newGroupType=$$v},expression:"newGroupType"}})]}}],null,false,1779034304)}),_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"newMasterGroupId","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Autocomplete',{attrs:{"label":"Группы мастеров","placeholder":"Группы мастеров","options":_vm.availableGroups,"outlined":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.newMasterGroupId),callback:function ($$v) {_vm.newMasterGroupId=$$v},expression:"newMasterGroupId"}})]}}],null,false,2859674247)}),_c('ValidationProvider',{staticClass:"space-b-8",attrs:{"name":"reason","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"reason","label":"Причина перевода","placeholder":"Введите причину","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})]}}],null,false,999838638)}),_c('ValidationProvider',{attrs:{"name":"comment","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"comment","label":"Комментарий для мастера","placeholder":"Введите комментарий","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})]}}],null,false,1555581568)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }