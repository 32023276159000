






































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UsersSingleSearch from '@/components/_uikit/search/UsersSingleSearch.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import {
  GroupType,
  MasterMoveReasonRequest,
  MasterShortResource,
  NameValueChildrenResource,
  UserManagerResource,
} from '@/store/types'
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'

@Component({
  components: {
    Autocomplete,
    Dialog,
    ModalWrapper,
    Select,
    TextInput,
    UsersSingleSearch,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class TransferMasterModal extends Mixins(MasterGroupMixin, PermissionsMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: null })
  private master!: MasterShortResource

  @Prop({ default: null })
  private user!: UserManagerResource

  @Prop({ default: null })
  private masterGroupId!: number

  private availableGroupTypes: NameValueChildrenResource[] = []
  private availableGroups: NameValueChildrenResource[] = []
  private newGroupType = 0
  private newMasterGroupId = 0
  private oldMasterGroupId = 0
  private form: MasterMoveReasonRequest = {
    comment: '',
    reason: '',
  }

  private get oldMasterGroups () {
    return DictionaryModule.masterGroupsByUserId.map(item => ({ name: item.title, value: item.id }))
  }

  private get masterFullName() {
    if (this.master) {
      return `${this.master.user.name} ${this.master.user.surname}`
    } else if (this.user) {
      return `${this.user.name} ${this.user.surname}`
    }
    return ''
  }

  private mounted () {
    this.syncGroups()
  }

  private syncGroups () {
    if (this.masterGroupId) {
      this.oldMasterGroupId = this.masterGroupId
      DictionaryModule.fetchMasterGroupByMasterId(this.master ? this.master.user.id : this.user.id)
        .then(() => {
          this.fetchAvailableGroups()
        })
        .catch(this.notifyError)
    }
  }

  private fetchAvailableGroups () {
    if (this.oldMasterGroupId) {
      ManagerGroupsModule.fetchGroupsForMaster({
        masterGroupId: this.oldMasterGroupId,
        masterId: this.master ? this.master.user.id : this.user.id,
      })
        .then((response: NameValueChildrenResource[]) => {
          this.newGroupType = 0
          this.newMasterGroupId = 0
          this.availableGroupTypes = [...response]
          this.form = {
            comment: '',
            reason: '',
          }
        })
        .catch(this.notifyError)
    }
  }

  private handleSelectGroupType (value: GroupType) {
    const group = this.availableGroupTypes.find((groupType: NameValueChildrenResource) => groupType.value === value)

    if (group) {
      this.newMasterGroupId = 0
      this.availableGroups = group.children || []
    }
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerGroupsModule.transferGroupMaster({
            masterGroupID: this.oldMasterGroupId,
            masterID: this.master ? this.master.user.id : this.user.id,
            newMasterGroupID: this.newMasterGroupId,
            params: this.form,
          })
            .then(() => {
              this.notifySuccess('Мастер успешно переведен')
              this.$emit('success')
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
  }

  @Watch('visible')
  private watchVisible (value: boolean) {
    if (value) {
      this.syncGroups()
    }
  }
}
